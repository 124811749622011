// MARK: React
import React from "react";

// MARK: Components
import { LittleTitle } from "../../startappComponents/LittleTitle";
import Title from "../../components/Title";
import Paragraph from "../../startappComponents/Paragraph";
import LinkSection from "../../startappComponents/LinkSection";

// MARK: Next
import Image from "next/image";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Implementation
interface IProps {
	imageSource: string;
	subtitle: string;
	title: string;
	description: string;
	linkButton?: {
		label: string;
		internal: {
			href: string;
			as: string;
		};
	};
	rowReversed?: boolean;
	thinImage?: boolean;
}

const MethodologyStep: React.FC<IProps> = (props) => {
	const {
		imageSource,
		subtitle,
		title,
		description,
		linkButton,
		rowReversed,
		thinImage,
	} = props;

	return (
		<div className={`${classes.methodologyStep} ${rowReversed ? classes.rowReversed : ""}`}>
			<div className={classes.imageField}>
				<Image
					height={450}
					width={450}
					className={
						thinImage
							? classes.methodologyStepThinImage
							: classes.methodologyStepFatImage
					}
					src={imageSource}
					alt={subtitle.toLowerCase()}
				/>
			</div>
			<div className={classes.methodologyStepInfo}>
				<LittleTitle containerStyle={classes.infoSubtitle}>
					{subtitle}
				</LittleTitle>
				<Title
					Type="h3"
					theme="white"
					customStyle={classes.infoTitle}
				>
					{title}
				</Title>
				<Paragraph textStyle={classes.infoDescription}>
					{description}
				</Paragraph>
				{linkButton && (
					<LinkSection
						className={classes.methodologyStepLink}
						text={linkButton.label}
						internal={linkButton.internal}
					/>
				)}
			</div>
		</div>
	);
};

export default MethodologyStep;
