// MARK: React
import React, { FC } from "react";

// MARK: Styles
import classes from "./style.module.scss";
import ITextStyles from "../../interfaces/ITextStyles";

// MARK: Implementation
interface IProps extends ITextStyles {
	containerStyle?: string;
}

function getClass(text: ITextStyles) {
	switch (text.type) {
		case "primary": return classes.primary;
		case "secondary": return classes.secondary;
		case "light": return classes.light;
		default: break;
	}
}

export const Title: FC<IProps> = (props) => {
	return (
		<p className={`${classes.container} ${props.containerStyle} ${getClass(props)}`}>
			{props.children}
		</p>
	);
};
