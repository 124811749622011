// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import Image from "next/image";
import { useRouter } from "next/router";

// MARK: Components
import { TagTitle } from "../../TagTitle";
import Paragraph from "../../../startappComponents/Paragraph";
import Title from "../../Title";

// MARK: Resources
import Strings from "../../../resources/Strings";

const WhatDoSection: React.FC = () => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });
	const sectionStrings = strings.pages.home.whatDoSection;

	return (
		<div className={classes.container}>
			<div className={classes.titlesContainer}>
				<TagTitle title={sectionStrings.whatWeDo} />
				<Title
					Type="h3"
					theme="primary"
					customStyle={classes.paragraphTitle}
				>
					{sectionStrings.title}
				</Title>
				<Paragraph textStyle={classes.horizontalPadding}>
					{sectionStrings.textInfo}
				</Paragraph>
			</div>
			<div className={classes.containerImage}>
				<Image
					width={874}
					height={371}
					className={classes.image}
					src="/people_image.jpg"
					alt="pt-BR"
				/>
			</div>
		</div>
	);
};

export default WhatDoSection;
