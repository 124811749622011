// MARK: React
import React from "react";

// MARK: Next
import Image from "next/image";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Implementation
interface IProps {
	containerStyle?: string;
	src: string;
	title: string;
	subtitle: string;
}

export const SkillCard: React.FC<IProps> = (props) => {
	return (
		<div className={`${classes.container} ${props.containerStyle ? props.containerStyle : ""}`}>
			<Image
				width={74}
				height={80}
				className={classes.logo}
				src={props.src}
				alt="pt-BR"
			/>
			<h3>{props.title}</h3>
			<p>{props.subtitle}</p>
		</div>
	);
};
