// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { useRouter } from "next/router";

// MARK: Components
import { SkillCard } from "../../SkillCard";
import Paragraph from "../../../startappComponents/Paragraph";
import Title from "../../Title";
import LinkSection from "../../../startappComponents/LinkSection";

// MARK: Resources
import Routes from "../../../resources/Routes";
import Strings from "../../../resources/Strings";

const HowDoSection: React.FC = () => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });

	return (
		<div className={classes.container}>
			<Title Type="h2" theme="primary">
				{strings.pages.home.howDoSection.title}
			</Title>
			<Paragraph textStyle={classes.howDoParagraph}>
				{strings.pages.home.howDoSection.textInfo}
			</Paragraph>
			<div className={classes.skillRow}>
				<SkillCard
					src={"/DevelopmentIcon.png"}
					title={strings.pages.home.whatDoSection.skills.title1}
					subtitle={strings.pages.home.whatDoSection.skills.description1}
				/>
				<SkillCard
					src={"/ConsultingIcon.png"}
					title={strings.pages.home.whatDoSection.skills.title2}
					subtitle={strings.pages.home.whatDoSection.skills.description2}
					containerStyle={classes.skillTag}
				/>
				<SkillCard
					src={"/SolutionsIcon.png"}
					title={strings.pages.home.whatDoSection.skills.title3}
					subtitle={strings.pages.home.whatDoSection.skills.description3}
				/>
			</div>
			<LinkSection
				text={strings.pages.home.whatDoSection.buttonText}
				internal={Routes.talkToUs()}
				className={classes.button}
			/>
		</div>
	);
};

export default HowDoSection;
