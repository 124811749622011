// MARK: React
import React from "react";

// MARK: Next
import Image from "next/image";

// MARK: Components
import { SmallContactForm } from "../SmallContactForm";

// MARK: Styles
import classes from "./style.module.scss";

const PhoneForm = () => {
	return (
		<div className={classes.container}>
			<div>
				<Image
					layout="fixed"
					height="670"
					width="586"
					className={classes.backImage}
					src="/envImg.svg"
					alt="pt-BR"
				/>
			</div>

			<div className={classes.formContainer}>
				<SmallContactForm />
			</div>
		</div>
	);
};

export default PhoneForm;
