// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { useRouter } from "next/router";

// MARK: Hooks
import { useContactForm } from "../../hooks/useContactForm";

// MARK Components
import { Title } from "../../startappComponents/Title";
import TextInput from "../../startappComponents/TextInput";
import Paragraph from "../../startappComponents/Paragraph";
import Button from "../../startappComponents/Button";

// MARK: Resources
import Strings from "../../resources/Strings";

export const SmallContactForm: React.FC = () => {
	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });
	const { field, submit, loading, submited } = useContactForm(
		strings,
		{
			requesterOptions: {
				handleError: (err, setError) => {
					alert(err.message);
					setError(null);
				},
			},
		},
	);

	return (
		!submited
			? (
				<form
					className={classes.container}
					onSubmit={submit}
				>
					<Paragraph textStyle={classes.paragraph}>
						{strings.pages.home.welComeSection.contact.talkToYou}
					</Paragraph>
					<TextInput
						{...field("name")}
						label={strings.general.name}
					/>
					<TextInput
						{...field("email")}
						label={strings.general.email}
					/>
					<TextInput
						{...field("phone")}
						typeNumber={true}
						label={strings.general.phone}
					/>
					<Button
						type="submit"
						loading={loading}
						title={strings.pages.home.welComeSection.contact.talkToMe}
						className={classes.button}
					/>
					<Title containerStyle={classes.titleShareContact}>
						{strings.pages.home.welComeSection.contact.weWillNotShare}
					</Title>
				</form>
			) : (
				<Paragraph size="small">
					{strings.alerts.yourContactRequestHasBeenSent}
				</Paragraph>
			)
	);
};
