// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Implementation
interface IProps {
	className?: string;
	text: string;
	internal: {
		href: string;
	};
}

const LinkSection: React.FC<IProps> = (props) => {
	return (
		<a
			role="button"
			rel="noreferrer"
			href={props.internal.href}
			className={`${props.className} ${classes.container}`}
		>
			{props.text}
		</a>
	);

};

export default LinkSection;
