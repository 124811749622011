// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { useRouter } from "next/router";

// MARK: Components
import LinkSection from "../../../startappComponents/LinkSection";
import Paragraph from "../../../startappComponents/Paragraph";
import PhoneForm from "../../PhoneForm";
import Title from "../../Title";

// MARK: Resources
import Routes from "../../../resources/Routes";
import Strings from "../../../resources/Strings";

// MARK: Implementation
const WelcomeSection: React.FC = () => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });

	return (
		<div className={classes.container}>
			<div className={classes.content}>
				<div className={classes.textContainer}>
					<Title
						Type="h1"
						theme="white"
						customStyle={classes.title}
					>
						{strings.pages.home.welComeSection.title.mainText}
						<span className={classes.subTitle}>
							{strings.pages.home.welComeSection.title.coloredText}
						</span>
					</Title>

					<Paragraph textStyle={classes.paragraph}>
						{strings.pages.home.welComeSection.infoText}
					</Paragraph>
					<LinkSection
						text={strings.pages.home.welComeSection.startProject}
						internal={Routes.talkToUs()}
					/>
				</div>
				<PhoneForm />
			</div>
		</div>
	);
};

export default WelcomeSection;
