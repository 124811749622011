// MARk: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { useRouter } from "next/router";

// MARK: Components
import { TagTitle } from "../../TagTitle";
import MethodologyStep from "../../MethodologyStep";
import Title from "../../Title";
import Paragraph from "../../../startappComponents/Paragraph";

// MARK: Resources
import Routes from "../../../resources/Routes";
import Strings from "../../../resources/Strings";

// MARK: Implementation
interface IStep {
	imageSource: string;
	subtitle: string;
	title: string;
	description: string;
}

const MethodologySection: React.FC = () => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });
	const pageStrings = strings.pages.home;
	const sectionStrings = pageStrings.methodology;

	const imageSources = [
		"/methodologyBeginImage.svg",
		"/MethodologyStructureImage.svg",
		"/MethodologyDesignImage.svg",
		"/MethodologyCodeImage.svg",
		"/MethodologyTestImage.svg",
		"/MethodologyLaunchImage.svg",
		"/MethodologyMaintenanceImage.svg",
	];

	const steps: IStep[] = sectionStrings.steps.map((value, index) => ({
		imageSource: imageSources[index],
		...value,
	}));

	return (
		<div
			id="methodology"
			className={classes.methodologyContainer}
		>
			<TagTitle title={sectionStrings.tagTitle} />
			<Title Type="h2" theme="secondary" customStyle={classes.greatTitle}>
				{sectionStrings.greatTitle}
			</Title>
			<Paragraph textStyle={classes.paragraph}>
				{sectionStrings.paragraph}
			</Paragraph>
			{steps.map((step, index) => (
				<MethodologyStep
					key={step.subtitle.toLowerCase()}
					imageSource={step.imageSource}
					subtitle={step.subtitle}
					title={step.title}
					description={step.description}
					linkButton={(index % 3 === 0)
						? {
							label: pageStrings.welComeSection.talkToUs,
							internal: Routes.talkToUs(),
						} : undefined
					}
					rowReversed={index % 2 !== 0}
					thinImage={index === 5}
				/>
			))}
		</div>
	);
};

export default MethodologySection;
