// MARK: React
import * as React from "react";

// MARK: Next
import { NextPage } from "next";
import { useRouter } from "next/router";

// MARK: Components
import MainLayout from "./../startappComponents/MainLayout";
import HomeHead from "../components/Home/HomeHead";
import WhatDoSection from "../components/Home/WhatDoSection";
import WelcomeSection from "../components/Home/WelcomeSection";
import MethodologySection from "../components/Home/MethodologySection";
import HowDoSection from "../components/Home/HowDoSection";
import ContactSection from "../components/Home/ContactSection";

// MARK: Resources
import Routes from "../resources/Routes";

// MARK: Implementation
export interface IProps { }

const HomePage: NextPage<IProps> = () => {
	const router = useRouter();

	if (typeof window !== "undefined") {
		window.history.replaceState(
			window.history.state,
			document.title,
			Routes.index(router.locale, window.location.search).as,
		);
	}

	return (
		<>
			<HomeHead />
			<MainLayout>
				<WelcomeSection />
				<HowDoSection />
				<MethodologySection />
				<WhatDoSection />
				<ContactSection />
			</MainLayout>
		</>
	);
};

export default HomePage;
